<template>
  <v-app>
    <div v-if="this.signedIn">
      <div class="home" v-if="!$vuetify.breakpoint.xs">
        <div class="lessonHeader">
          <h1 class="ma-0">{{ this.practiceCase }}</h1>
          <h2>{{ this.practiceLesson[0].toUpperCase() + this.practiceLesson.substring(1) }}</h2>
        </div>
      </div>

      <div class="greetingContainerMobile" v-if="$vuetify.breakpoint.xs">
        <h1 class="ma-0">{{ this.practiceCase }}</h1>
        <h2>{{ this.practiceLesson[0].toUpperCase() + this.practiceLesson.substring(1) }}</h2>
      </div>

      <div class="content">

        <vue-simple-markdown :source="lessonContent"></vue-simple-markdown>

      </div>

      <div class="mt-12 buttons">
        <v-btn v-if="!$vuetify.breakpoint.xs" dense dark hide-details x-large elevation=0 color="#90be6d" height=56px @click="goToPractice()">Practice {{this.lessonName}}</v-btn>
        <v-btn v-if="$vuetify.breakpoint.xs" dense dark hide-details large elevation=0 color="#90be6d" height=56px @click="goToPractice()">Practice {{this.lessonName}}</v-btn>
      </div>

    </div>
  </v-app>
</template>

<script>
  import {db} from '../firebase/db';
  import firebase from 'firebase';

  export default {
    title: "Lesson",
    props: ['uid', 'username', 'lesson'],
    data() {
      return {
        signedIn: false,
        lessonContent: "",
        lessonName: "",
        practiceLesson: "",
        practiceCase: ""
      }
    },
    methods: {

      goToPractice(){
        this.$router.push({ name: 'Practice', params: {lesson: this.lesson }})
      },

    },
    async beforeMount() {

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          this.user = user;
          this.uid = user.uid;
          this.username = user.displayName;
          this.signedIn = true

        } else {
          // User is signed out
          // ...
          this.signedIn = false;
          this.$router.push({ name: 'Index'})
        }
      });



      if (this.lesson == null) {
        this.$router.push({ name: 'Dashboard'})
      }






      var lessonNamesFull = ['masculine nouns', 'masculine adjectives', 'feminine nouns', 'feminine adjectives', 'neuter nouns', 'neuter adjectives', 'plural adjectives']
      var caseAbbreviations = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst']
      var cases = ['Nominative', 'Accusative', 'Prepositional', 'Genitive', 'Dative', 'Instrumental']

      var caseAbbrev = this.lesson.substring(0, this.lesson.length - 1)
      var lessonCase = cases[caseAbbreviations.indexOf(caseAbbrev)]
      this.practiceCase = lessonCase

      var lessonNumber = this.lesson.substring(this.lesson.length - 1, this.lesson.length)
      this.practiceLesson = lessonNamesFull[lessonNumber - 1]

      this.lessonName = lessonCase + " " + lessonNamesFull[lessonNumber - 1]

      var lessonContent = await db.collection('Lessons').doc('Lessons').collection(lessonCase).doc(lessonNumber).get();
      this.lessonContent = lessonContent.data().content;


      console.log(this.lesson);

      if (this.lesson == 'nom1') {
        this.lessonName = "identify grammatical gender"
        this.practiceLesson = "identify grammatical gender"
      } else if (this.lesson == 'nom2') {
        this.lessonName = "nominative nouns"
        this.practiceLesson = "nominative nouns"
      } else if (this.lesson == 'nom3') {
        this.lessonName = "nominative adjectives"
        this.practiceLesson = "nominative adjectives"
      }



    }
  }
</script>


<style scoped>
  .home {
    height: 180px;
    text-align: left;
    background-color: #383838;
    background-image: url(../assets/sputnik.png);
    background-size: 160px;
    background-repeat: no-repeat;
    background-position: calc(50vw + 320px) 0px;
    position: relative;
    align-content: center;
  }

  .lessonHeader {
    color: #fff;
    max-width: 1000px;
    font-size: 1.2em;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(50vw - 500px)
  }

  .content {
    padding: 20px 0 0 0;
    text-align: left;
    max-width: 1000px;
    margin: auto;
  }

  .buttons {
    margin: 10px 0 40px 0;
    display: flex;
    justify-content: center;

  }
</style>






<style scoped>
  @media screen and (max-width: 1024px) {

    h1 {
      font-size: 2em;
      margin-bottom: 20px;
    }

    .greetingContainerMobile {
      height: auto;
      background-color: #383838;
      color: #fff;
      text-align: left;
      padding: 10px;
    }

    .content {
      width: 100vw;
      max-width: 100vw;
      text-align: left;
      padding: 20px 10px;
      margin: auto;
    }

    .buttons {
      width: 100vw;
      max-width: 100vw;
      margin: 10px 0 40px 0;
      padding: 0 10px;
      display: flex;
      justify-content: center;
    }

  }
</style>















<!--  -->
